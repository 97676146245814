// Copyright 2021
// Polus

import * as React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/SEO';

const Blog = (): React.ReactElement => (
  <Layout>
    <Seo title="About" />
    <div className="space-y-6 px-4 font-medium md:px-0 md:text-lg">
      <p>Hi,</p>
      <p>
        We're Hirsh and Jonathan, two friends who met at University. In 2021, while studying
        Medicine and Hebrew, we got on a Zoom call to catch up during one of the UK's Covid
        lockdowns. We realized we were both unhappy with the spaced repetition apps we were using
        (Anki, Quizlet, among many others), so we decided to build a better alternative. That's how
        we came up with Studius.
      </p>
      <p>
        Since then, many people around the world have been using Studius to study Spanish, German,
        French, Mathematics, Economics, Biology, Physics, Medicine, Arabic, Engineering, and many
        other things.
      </p>
      <p>
        Studius is proudly self-funded and built in our free time. We've learned how to build
        software at some of the world's largest tech companies and want to use our skills to make
        studying more fun.
      </p>
      <p>Thanks for checking out Studius – we hope you find it useful!</p>
      <div>
        <p>
          <a
            className="font-bold text-blue-500 hover:underline"
            href="https://www.linkedin.com/in/hirsh-agarwal/"
            rel="noreferrer"
            target="_blank"
          >
            Hirsh
          </a>{' '}
          &{' '}
          <a
            className="font-bold text-blue-500 hover:underline"
            href="https://www.linkedin.com/in/drummerjolev/"
            rel="noreferrer"
            target="_blank"
          >
            Jonathan
          </a>
        </p>
        <p className="pt-2 text-sm">Email us: team[at]studius.ai</p>
      </div>
    </div>
  </Layout>
);

export default Blog;
